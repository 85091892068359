import * as deepmerge from 'deepmerge';

import { ENVIRONMENT_GLOBAL } from '../global_env';

export const environment = deepmerge(ENVIRONMENT_GLOBAL, {
  envName: 'test',
  production: true,
  matomoSrc: 'https://matomo.meinhplus.de/statistic/js/container_EfEzdlyc_test_d8abcd35cbfd5f1a7ff6fc23.js',
  redirect: {
    host: 'https://test-administration.meinhplus.de'
  },
  auth_L1: {
    client_id: 'iamlevel2',
    scope: 'phone email profile openid',
    endpoint: {
      host: 'https://test-account.meinhplus.de',
      paths: {
        prefix: '/auth/realms/hekatron-tuer-tor'
      }
    }
  },
  backend: {
    scope: 'l2m',
    client_id: 'l2m-webbapp-yDhzjjofEVjwx3fN',
    endpoint: {
      host: 'https://api.test-administration.meinhplus.de'
    }
  },
  files: {
    paths: {
      host: 'https://test-administration.meinhplus.de' //TODO check
    }
  },
  links: {
    applications: {
      mh2: 'https://test.meinhplus.de',
      hcp: 'https://test-dashboard.meinhplus.de',
      oms: 'https://test-oms.meinhplus.de'
    }
  }
});
